import React from 'react';

import aboutUs from "../images/aboutUs.svg";
import discover from "../images/whatCanYouDiscover.svg";
import application from "../images/applications.svg";
import MachineButtonOption from "./MachineButtonOption";

const MachineThreeButtonOptions = ({burt}) => {
  return(
    <div className="machine-three-button-options horizontally-center">
      <MachineButtonOption
        image={aboutUs}
        text="About us"
        toId="machine-about-us-title"
      />
      <MachineButtonOption
        image={discover}
        text="Detection"
        toId="machine-detection-title"
      />
      <MachineButtonOption
        image={application}
        text="Applications"
        toId="machine-application-title"
      />

    </div>
  );
};

export default MachineThreeButtonOptions;