import "./normalise.css";
import "./app.sass";
import "./stylesheets/_components.sass";
import "./stylesheets/home.sass";
import "./stylesheets/machine.sass";
import "./stylesheets/contact.sass";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
