import React from "react";

import TeamMember from "./TeamMember";

import thrimendra from "../images/team/Dr Thrimendra Kaushika Dissanayake - Circle - New.png"
import syed from "../images/team/Dr. Syed Shakeel Ahmed - Circle.png"
import sabree from "../images/team/Najumudeen Mohamed Sabree Ismeth - Circle.png"
import ContactFormBubble from "./ContactFormBubble";

const HomeFinancialModel = () => {
  return (
    <div style={{ backgroundColor: "#040043", paddingBottom: "0" }} className="home-financial-model section">
      <div style={{ marginBottom: "0" }} className="section-wrapper">
        <h1 style={{ color: "white" }}>Core Team</h1>
        <div className="home-financial-model-container">
          <TeamMember
            image={syed}
            name="Dr. Syed Shakeel Ahmed"
            role="Chief Executive Officer | Founder"
            degree="University of Hong Kong BSc, Microbiology (Food)"
            secondDegree="PhD, Microbiology (Genetics & Virology)"
          />
          <TeamMember
            image={thrimendra}
            name="Dr. Thrimendra Kaushika Dissanayake"
            role="Chief Technology Officer"
            degree="University of Hong Kong BSc, Biological Sciences"
            secondDegree="PhD, Microbiology (Virology)"
          />
          <TeamMember
            image={sabree}
            name="Najumudeen Mohamed Sabree Ismeth"
            role="Head of Operations | Biomedical Engineer"
            degree="University of Hong Kong BEng, Medical Engineering"
            secondDegree="The Chinese University of Hong Kong Masters, Biomedical Engineering"
          />
        </div>
        <ContactFormBubble />
      </div>
    </div>
  );
};

export default HomeFinancialModel;