import React from "react";

import ContactFormBubble from "../Components/ContactFormBubble";

class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    window.scrollTo(0, 0)
  }
  render() {
    return(
      <div>
        <div style={{backgroundColor: "white"}} className="no-top-padding no-top-margin">
          <div className="rounded-top" />
            <div id="contact-form-wrapper" className="section section-wrapper">
              <ContactFormBubble />
            </div>
        </div>
      </div>
    );
  }
}

export default Contact;