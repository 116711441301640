import React from 'react';

const HomeGraphicTextSection = ({n, image, h1Val, h2Val, h3Val, h3Color}) => {

  const homeGraphicTextSectionN = "home-graphic-text-section-" + n;
  const homeGraphicTextSectionNImg = "home-graphic-text-section-" + n + "-img";
  const homeGraphicTextSectionNText = "home-graphic-text-section-" + n + "-text";

  return(
    <div className={homeGraphicTextSectionN}>
      <img
        className={`${homeGraphicTextSectionNImg} home-step-reveal-element-${n}`}
        src={image}
        alt="Steps Graphic"
      />
      <div className={`text-section-wrapper home-step-reveal-element-${n}`}>
        <h1>{h1Val}</h1>
        <h2>{h2Val}</h2>
        <h3 style={{color: h3Color}} className={homeGraphicTextSectionNText}>
          {h3Val}
        </h3>
      </div>
    </div>
  );
};

export default HomeGraphicTextSection;