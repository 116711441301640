import React from "react";

const HomeSolution = () => {
  return(
    <div className="home-problem section off-white-bg-color">
      <div className="section-wrapper">
        <h1>The Solution</h1>
        <p className="home-problem-secondary">
          Spidfier is a toolkit that offers accurate fish identification and more.
        </p>
        <p>
          Using our machine learning algorithms and proprietary database,
          we are able to derive 5 key metrics from DNA data alone.
        </p>

        <div className="home-reveal-element-up">
          <h2>1</h2>
          <h3>Fish Identification</h3>
          <p>
            DNA from sample is compared to a database, capable of 99.7% accuracy in species identification.
          </p>
        </div>

        <div className="home-reveal-element-up">
          <h2>2</h2>
          <h3>Fish Origins</h3>
          <p>
            Epigenetic changes in DNA due to environmental factors can be measured and put through machine
            learning to determine Fish Origin, even for migratory fish.
          </p>
        </div>

        <div className="home-reveal-element-up">
          <h2>3</h2>
          <h3>Fish Freshness</h3>
          <p>
            By using machine learning, the time at which a sample has been left unfrozen can be determined.
          </p>
        </div>

        <div className="home-reveal-element-up">
          <h2>4</h2>
          <h3>Fish Pathogens</h3>
          <p>
            Any bacteria, virus or parasite present in the sample will be detected.
          </p>
        </div>

        <div className="home-reveal-element-up">
          <h2>5</h2>
          <h3>Evidence of Short Weighting</h3>
          <p>
            Abnormally low counts of eukaryotic DNA copy number from samples can indicate tampering through
            short weighting.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeSolution;