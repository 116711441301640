import React from 'react';

const MachineDetectionExample = ({title, info}) => {
  return(
    <div>
      <h1 className="no-btm-margin">{title}</h1>
      <p>{info}</p>
    </div>
  );
};

export default MachineDetectionExample;