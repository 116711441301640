import React from "react";
import diagram from "../images/diagram.svg";

const HomeWorks = () => {
  return(
    <div className="home-works section">
      <div className="section-wrapper">
        <h1>How Spidfier Works</h1>
        <img className="home-works-diagram-img" src={diagram} alt="Diagram" />
      </div>
    </div>
  );
};

export default HomeWorks;