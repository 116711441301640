import React from 'react';

const InformationSection = ({children, bgImg, bgSize}) => {

  const styles = {
    backgroundImage: "url(" + bgImg + ")",
    backgroundSize: bgSize,
  };

  return(
    <div style={styles} className="information-section">
      {children}
    </div>
  );
};

export default InformationSection;