import React from 'react';

const MachineAboutUs = () => {
  return(
    <div style={{margin: "0"}}
         className="section-only-padding machine-about-us white-bg-color">
      <div className="section-wrapper">
        <h1 id="machine-about-us-title">Who we are</h1>
        <h1 className="no-btm-margin">About us</h1>
        <p>
          Spidfier is a Hong Kong based startup aimed to promote fish
          sustainability by creating greater transparency in the seafood
          industry and providing customers with greater information.
        </p>
        <h1 className="no-btm-margin">Our aim</h1>
        <p>
          Spidfier aims to combat fish fraud and provide transparency
          in the seafood industry by providing accurate DNA testing
          to enhance food safety and add greater information to fish products.
        </p>
      </div>
    </div>
  );
};

export default MachineAboutUs;