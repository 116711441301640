import React from "react";

const TeamMember = ({ image, name, role, degree, secondDegree }) => {
  return (
    <div className="team-member">
      <div className="team-member-container">
        <div className="team-member-img-container">
          <img src={image} alt={name} />
        </div>
        <h2 className="team-member-name">{name}</h2>
        <h3 className="team-member-role">{role}</h3>
        <p>{degree}  {secondDegree && <br/>} {secondDegree}</p>
      </div>
    </div>
  );
};

export default TeamMember;