import React from "react";
import Slider from "react-slick";

import degrees from "../images/other_logos/170degrees.png";
import afcd from "../images/other_logos/afcd.png";
import msc from "../images/other_logos/msc.png";
import copenhagen from "../images/other_logos/copenhagen.png";
import hku from "../images/other_logos/hku.png";
import thei from "../images/other_logos/thei.png";
import inpng from "../images/other_logos/in.png";
import wwf from "../images/other_logos/wwf.png";
import parknshop from "../images/other_logos/parknshop.jpeg";
import metrosourcing from "../images/other_logos/metrosourcing.png";
import qima from "../images/other_logos/qima.png";
import dennisgroup from "../images/other_logos/dennisgroup.png";
import bureauveritas from "../images/other_logos/bureauveritas.png";
import mandc from "../images/other_logos/mandc.png";
import fishprint from "../images/other_logos/fishprint.png";

const HomeInvestorsLogos = () => {
  let settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true
  };

  return(
    <div style={{paddingLeft: "0", paddingRight: "0"}} className="home-investors-logos off-white-bg-color section">
      <div style={{paddingLeft: "0", paddingRight: "0"}} className="section-wrapper">
        <p style={{marginTop: "0", paddingBottom: "8px", textAlign: "center", fontWeight: "bold", color: "rgba(14,30,37,.64)"}}>Current Traction</p>
        <Slider {...settings}>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={degrees} alt="170 Degrees" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={afcd} alt="AFCD" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={msc} alt="MSC" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={copenhagen} alt="University of Copenhagen" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={hku} alt="University of Hong Kong" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={thei} alt="Thei" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={inpng} alt="IN" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={mandc} alt="M & C" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={fishprint} alt="fishprint" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={wwf} alt="WWF" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={parknshop} alt="ParkNShop" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={metrosourcing} alt="Metro Sourcing" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={qima} alt="QIMA" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={dennisgroup} alt="Dennis Group" />
          </div>
          <div className="home-investors-logos-container">
            <img className="home-investors-logo" src={bureauveritas} alt="Bureau Veritas" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default HomeInvestorsLogos;