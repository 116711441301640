import React from "react";
import ContactForm from "./ContactForm";

const ContactFormBubble = () => {
  return(
    <div id="contact-form" className="no-bg-color vertically-center"> {/* section-wrapper*/}
      <div id="contact-form-fields">
        <h1 className="center-text">Contact us</h1>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactFormBubble;