import React from 'react';
import axios from 'axios';
import { FormGroup, makeStyles, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import emailjs from "@emailjs/browser"

const ContactForm = () => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const [values, setValues] = React.useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const queueRef = React.useRef([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift());
      setOpen(true);
    }
  };

  const handleClick = message => () => {
    queueRef.current.push({
      message,
      key: new Date().getTime(),
    });

    if (open) {
      // immediately begin dismissing current message
      // to start showing new one
      setOpen(false);
    } else {
      processQueue();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    processQueue();
  };

  const submitForm = e => {
    e.preventDefault();

    console.log("MESSAGE:" + values.message);

    emailjs.sendForm('service_fym41no', 'contact', e.target, 'XTWYpmqH1UgTJBuW4').then(handleClick("Message sent successfully"))
      .catch(handleClick("Error sending message"))
      .finally(document.getElementById('contact-form-reset').reset());
  };

  return (
    <form id="contact-form-reset" onSubmit={submitForm}>
      <FormGroup className="contact-form-form-group">
        <TextField
          label="Full Name"
          margin="normal"
          className={classes.textField}
          onChange={handleChange('name')}
          variant="outlined"
          name="name"
        />
        <TextField
          label="Email"
          margin="normal"
          className={classes.textField}
          onChange={handleChange('email')}
          variant="outlined"
          type="email"
          name="email"
          autoComplete="email"
        />
        <TextField
          label="Message"
          multiline
          rows="4"
          margin="normal"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange('message')}
          name="message"
        />

        <Button
          type="submit"
          variant="contained"
          className={classes.button}
          onClick={e => submitForm}
        >
          Submit
        </Button>
      </FormGroup>
      <Snackbar
        className="snackbar"
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{messageInfo ? messageInfo.message : undefined}</span>}
      />
    </form>
  );
};

export default ContactForm;