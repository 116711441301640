import React from "react";

const TextOnImg = ({image, header, text, linkDescriptor, link, className}) => {
  return(
    <div style={{backgroundImage: `url(${image})`}} className={"text-on-img"}>
      <div className={`text-on-img-content ${className}`}>
        <h2>
          <h1 className="no-top-margin no-btm-margin bold-text">{header}</h1>
          {text}
        </h2>
        <a className="text-on-img-link" href={link} rel="noopener" target="_blank">-{linkDescriptor}</a>
      </div>
    </div>
  );
};

export default TextOnImg;