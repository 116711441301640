import React from "react";
import {Link} from "react-router-dom";
import ScrollMagic from 'scrollmagic';
import Parallax from 'parallax-js';

import SkewSection from "../Components/SkewSection";
import HomeSkewContent from "../Components/HomeSkewContent";
import HomeStepsSection from "../Components/HomeStepsSection";
import meatSampling from "../images/1_meat_sampling.svg";
import HomeGraphicTextSection from "../Components/HomeGraphicTextSection";
import HomeProblem from "../Components/HomeProblem";
import HomeThreat from "../Components/HomeThreat";
import HomeFinancialModel from "../Components/HomeFinancialModel";
import HomeWhySpidfier from "../Components/HomeWhySpidfier";
import HomeSolution from "../Components/HomeSolution";
import HomeInvestorsLogos from "../Components/HomeInvestorsLogos";
import HomeInvestors from "../Components/HomeInvestors";

import logoWithBubbles from "../images/logoBubblesBg.svg";
import diagram from "../images/diagram.svg";
import HomeWorks from "../Components/HomeWorks";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.scrollMagicController = new ScrollMagic.Controller();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // set scroll magic animations
    this.setScrollMagicScenes();
    this.homeFrontParallax = new Parallax(this.homeFrontParallaxScene);
    this.homeFrontTitleParallax = new Parallax(this.homeFrontParallaxTitleScene);
    this.homeFrontParallaxMB = new Parallax(this.homeFrontParallaxMBScene);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.homeFrontParallax.disable();
    this.homeFrontTitleParallax.disable();
    this.homeFrontParallaxMB.disable();
  }

  setScrollMagicScenes() {
    // home skew animation
    let homeSkewRevealElements = document.getElementsByClassName("home-reveal-element-up");
    for (let i = 0; i < homeSkewRevealElements.length; i ++) {
      new ScrollMagic.Scene({
          triggerElement: homeSkewRevealElements[i],
          offset: 50,
          triggerHook: 0.9,
          reverse: false,
        })
        .setClassToggle(homeSkewRevealElements[i], "visible")
        .addTo(this.scrollMagicController);
    }

    // home steps animation
    let homeStepRevealElements1 = document.getElementsByClassName("home-step-reveal-element-1");
    let homeStepRevealElements2 = document.getElementsByClassName("home-step-reveal-element-2");
    for (let i = 0; i < homeStepRevealElements1.length; i ++) {
      new ScrollMagic.Scene({
          triggerElement: homeStepRevealElements1[i],
          offset: 50,
          triggerHook: 0.9,
          reverse: false,
        })
          .setClassToggle(homeStepRevealElements1[i], "visible")
          .addTo(this.scrollMagicController);
    }
    for (let i = 0; i < homeStepRevealElements2.length; i ++) {
      new ScrollMagic.Scene({
          triggerElement: homeStepRevealElements2[i],
          offset: 50,
          triggerHook: 0.9,
          reverse: false,
        })
          .setClassToggle(homeStepRevealElements2[i], "visible")
          .addTo(this.scrollMagicController);
    }
  }

  render() {
    return(
      <div>
        <div id="home-front" style={{marginBottom: "-48px"}} className="section primary-dark-accent-bg">
          <div style={{marginTop: "0"}} className="section-wrapper no-bg-color">
            <div id="home-front-text-img-wrapper">
              <div ref={el => this.homeFrontParallaxScene = el}
                   id="logo-with-bubbles-wrapper"
                   className="center-text">
                <img className="logo-with-bubbles layer"
                     data-depth="0.2"
                     src={logoWithBubbles}
                     alt="Spidfier logo"
                />
              </div>
              <h1 ref={el => this.homeFrontParallaxTitleScene = el} id="home-front-text" className="center-text primary-accent-color">
                <span className="layer" data-depth="0.3">Combating fish fraud and enforcing sustainable fishing</span>
              </h1>
              <div ref={el => this.homeFrontParallaxMBScene = el}
                   id="logo-with-bubbles-wrapper-mb"
                   className="center-text">
                <div className="layer" data-depth="0.2">
                  <img className="logo-with-bubbles"
                       src={logoWithBubbles}
                       alt="Spidfier logo"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<SkewSection>*/}
        {/*  <HomeSkewContent />*/}
        {/*</SkewSection>*/}

        <HomeProblem/>
        <HomeThreat/>
        <HomeWhySpidfier/>
        <HomeSolution/>
        <HomeWorks />
        {/*<HomeInvestors/>*/}
        <HomeInvestorsLogos/>
        <HomeFinancialModel/>

        {/*<HomeStepsSection/>*/}

        {/*<div id="home-contact-section" className="section">*/}
        {/*  <div className="section-wrapper center-text">*/}
        {/*    <h3 className="no-top-margin">*/}
        {/*      Need more information or interested in a demo?*/}
        {/*      <Link to="/contact"><span style={{textDecoration: "underline"}}>Get in touch</span> &#8594;</Link>*/}
        {/*    </h3>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default Home;