import React from 'react';

import HomeGraphicTextSection from "./HomeGraphicTextSection";

import whaleWithTagImg from "../images/slide2.1.png"
import knifeFordImg from "../images/slide2.2.png"
import sustainableWhale from "../images/slide2.3.png"

const HomeThreat = () => {
  return (
    <div className="home-problem section off-white-bg-color">
      <div className="section-wrapper">
        <h1>The Threat</h1>
        <p className="home-problem-secondary">
          Fish Fraud dupes customers and devastates marine ecosystems.
        </p>

        <HomeGraphicTextSection
          n={1}
          image={whaleWithTagImg}
          h2Val="Direct Fraud"
          h3Val="Customers are overpaying for fish mislabeled as premium species."
        />

        <HomeGraphicTextSection
          n={1}
          image={knifeFordImg}
          h2Val="Health Threat"
          h3Val="Mislabeling confuses food safety inspectors."
        />

        <HomeGraphicTextSection
          n={1}
          image={sustainableWhale}
          h2Val="Harms Sustainability"
          h3Val="Mislabeling makes accurate counts of harvested seafood unreliable."
        />
      </div>
    </div>
  );
};

export default HomeThreat;