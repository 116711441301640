import React from 'react';

import TextOnImg from "./TextOnImg";

import dollarDownImg from "../images/slide1.1.png"
import whalePriceTagImg from "../images/slide1.2.png"
import hongKongImg from "../images/slide1.3.png"

const HomeProblem = () => {
  return (
    <div style={{marginTop: "-32px", marginBottom: "0", boxShadow: "none"}} className="home-problem section">
      <div style={{marginTop: "48px"}} className="section-wrapper">
        <h1>The Problem</h1>
        <p className="home-problem-secondary">
          Fish product mislabeling is rampant and causes direct fraud.
        </p>

        <TextOnImg
          image={dollarDownImg}
          header="USD 23 billion"
          text="of damage is caused by fish fraud each year"
          linkDescriptor="The FAO of the UN"
          link="http://www.fao.org/3/I8791EN/i8791en.pdf"
          className="home-reveal-element-up"
        />
        <TextOnImg
          image={whalePriceTagImg}
          header="20%"
          text="of global seafood is mislabelled"
          linkDescriptor="Oceana"
          link="https://usa.oceana.org/press-releases/oceana-finds-seafood-fraud-persists"
          className="home-reveal-element-up"
        />
        <TextOnImg
          image={hongKongImg}
          header="9 out of 11"
          text="seafood product samples from 6 different Hong Kong markets were mislabled"
          linkDescriptor="WWF"
          link="https://www.wwf.org.hk/en/?21480/Press-Release-New-study-finds-continued-seafood-product-mislabelling-at-local-supermarkets"
          className="home-reveal-element-up"
        />
      </div>
    </div>
  );
};

export default HomeProblem;