import React from 'react';
import MachineDetectionExample from "./MachineDetectionExample";

const MachineDetection = () => {
  return(
    <div style={{margin: "0"}}
         className="section-only-padding machine-detection off-white-bg-color">
      <div className="section-wrapper">
        <h1 id="machine-detection-title">What we can detect</h1>
        <p className="bold-text">
          Spidfier is able to sequence and analyze DNA from a sample to discover:
        </p>

        <MachineDetectionExample
          title="Fish Species"
          info="By comparing sample DNA to millions of fish species on file,
                a match can be found with over 99% accuracy."
        />
        <MachineDetectionExample
          title="Fish Origin"
          info="DNA is a living record of our lives, changes in environment  create
                minute differences in data which our machine learning  models use
                to determine where the fish was caught."
        />
        <MachineDetectionExample
          title="Pathogens"
          info="Any Bacteria, virus or parasite present on the sample will  also
                have its DNA sequenced, allowing us to detect any  potential
                harmful contamination."
        />
        <MachineDetectionExample
          title="Freshness"
          info="By modelling the rate at which bacteria grows on different fish,
                we can accurately determine how long any samples have been left
                out unrefridgerated."
        />
        <MachineDetectionExample
          title="Short-Weighting"
          info="Discrepencies in DNA count shows tampering through water  injection
                or other means."
        />
      </div>
    </div>
  );
};

export default MachineDetection;