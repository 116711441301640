import React from 'react';

const MachineApplication = () => {
  return(
    <div style={{margin: "0"}}
         className="section-only-padding machine-application white-bg-color">
      <div className="section-wrapper">
        <h1 id="machine-application-title">Who Spidfier is for</h1>

        <h1 className="no-btm-margin">Certifiers and Regulators</h1>
        <p>
          The Spidfier reduces test times, allows for on-site testing,
          decreases costs and offers greater analysis. With greater efficiency
          in testing, a tighter control over supply chain can be achieved.
        </p>

        <h1 className="no-btm-margin">Suppliers and Wholesalers</h1>
        <p>
          After interviewing chefs in Hong Kong, they find the biggest hurdle
          in purchasing raw fish is the trust they must put into their supplier
          to provide quality products, especially when purchasing for the first time.
          The Spidfier allows you to alleviate your customers concern with accurate
          DNA testing, adding value and legitimacy to your products.
        </p>
      </div>
    </div>
  );
};

export default MachineApplication;