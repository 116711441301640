import React from 'react';
import Typewriter from "typewriter-effect";

const MachineTitle = () => {
  return(
    <div className="machine-title" style={{paddingBottom: "16px"}}>
      <h1 className="center-text primary-white-color">What can you detect?</h1>
      <h2>
        <Typewriter options={{
          strings: ['Species', 'Origin', 'Pathogens', 'Freshness', 'Short-Weighting'],
          autoStart: true,
          loop: true,}}
        />
      </h2>
    </div>
  );
};

export default MachineTitle;