import React from "react";

import MachineThreeButtonOptions from "../Components/MachineThreeButtonOptions";
import MachineTitle from "../Components/MachineTitle";
import MachineAboutUs from "../Components/MachineAboutUs";
import MachineDetection from "../Components/MachineDetection";
import MachineApplication from "../Components/MachineApplication";
import Parallax from "parallax-js";

class Machine extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.button1Parallax = new Parallax(this.button1ParallaxScene);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.button1Parallax.disable();
  }

  render() {
    return(
      <div>
        <div id="machine-front"
             className="section center-text horizontally-center vertically-center primary-dark-accent-bg">
          <div>
            <div ref={el => this.button1ParallaxScene = el}>
              <div className="layer" data-depth="0.2">
                <MachineTitle />
              </div>
            </div>
            <MachineThreeButtonOptions />
          </div>
        </div>

        <MachineAboutUs />
        <MachineDetection />
        <MachineApplication />
      </div>
    );
  }
}

export default Machine;