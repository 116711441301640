import React from "react";

import InformationSection from "./InformationSection";
import HomeGraphicTextSection from "./HomeGraphicTextSection";

import greenWavyImg from "../images/wavy-bg-mob.svg"
import greenWavyImgL from "../images/wavy-bg.svg"
import rings from "../images/slide3.1.png";
import box from "../images/slide3.2.png";
import pieChart from "../images/slide3.3.png";
import clock from "../images/slide3.4.png";

const HomeWhySpidfier = () => {
  return(
    <div className="white-bg-color">
    <img src="https://images.unsplash.com/photo-1527154300610-c1a126502eac?ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80" id="gaping-fish" alt="Gaping Fish"/>
    <div className="home-why-spidfier section">
      <div className="section-wrapper">
        <h1>Why Spidfier</h1>
        <p>
          Spidfier lab-in-a-box fish identifier is compact, fast and easy to operate.
        </p>
        <p>
          Harnessing the power of the latest paradigm in DNA sequencing, fish-identification is a simple process.
        </p>
        <div className="home-reveal-element-up">
          <InformationSection bgImg={greenWavyImg} bgSize={"cover"}>
            <HomeGraphicTextSection
              n={1}
              image={rings}
              h2Val="Comprehensive"
              h3Val="Spidfier is able to test all fish species with the same test."
              h3Color="rgb(250,250,250)"
            />
            <HomeGraphicTextSection
              n={1}
              image={box}
              h2Val="Portable"
              h3Val="Spidfier is compact and transportable, enabling on-site testing."
              h3Color="rgb(250,250,250)"
            />
            <HomeGraphicTextSection
              n={1}
              image={pieChart}
              h2Val="Cost Effective"
              h3Val="Easy operation and prepackaged reagents negates the need for lab rental or lab technicians."
              h3Color="rgb(250,250,250)"
            />
            <HomeGraphicTextSection
              n={1}
              image={clock}
              h2Val="Fast"
              h3Val="Results can be delivered over the cloud to any connected device in 30 minutes."
              h3Color="rgb(250,250,250)"
            />
            {/*<Link to="/machine">*/}
            {/*  <span style={{textDecoration: "underline"}}>Learn more</span>&#8594;*/}
            {/*</Link>*/}
          </InformationSection>
        </div>
      </div>
    </div>
    </div>
  );
};

export default HomeWhySpidfier;