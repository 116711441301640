import React from 'react';
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import { Drawer, makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import IconButton from "@material-ui/core/IconButton";

import Home from "./views/Home";
import logo from "./images/logoWithBubblesText.svg";
import Machine from "./views/Machine";
import Contact from "./views/Contact";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

function App() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List className="drawer-link">
        <Link to="/">
          <ListItem button key="Home">
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        <Link to="/machine">
          <ListItem button key="Machine">
            <ListItemText primary="Spidfier Machine" />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List className="drawer-link">
        {['Contact'].map((text, index) => (
          <Link to={text}>
            <ListItem button key={text}>
              <ListItemText primary={text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  // TODO: see in return
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <div>
            {/*TODO: MENU MOBILE REMOVED*/}
            {/*<div id="menu-icon">*/}
            {/*  <IconButton onClick={toggleDrawer('left', true)}>*/}
            {/*    <MenuRoundedIcon className="primary-accent-color" />*/}
            {/*  </IconButton>*/}
            {/*  <Drawer open={state.left} onClose={toggleDrawer('left', false)}>*/}
            {/*    {sideList('left')}*/}
            {/*  </Drawer>*/}
            {/*</div>*/}
            <div id="top" className="center-text no-bg-color">
              <Link to="/"><img id="logo-top" src={logo} alt="Spidfier logo" /></Link>
              {/*TODO: MENU DESKTOP REMOVED*/}
              {/*<nav id="top-nav">*/}
              {/*  <List id="top-nav-list">*/}
              {/*    <Link to="/">Home</Link>*/}
              {/*    <Link to="/machine">Spidfier Machine</Link>*/}
              {/*    <Link to="/contact">Contact</Link>*/}
              {/*  </List>*/}
              {/*</nav>*/}
            </div>

            <Route exact path="/" component={Home} />
            {/*TODO: ROUTES REMOVED*/}
            {/*<Route path="/machine" component={Machine} />*/}
            {/*<Route path="/contact" component={Contact} />*/}

            <footer className="footer">
              <div className="section-wrapper no-bg-color footer-wrapper">
                {/*TODO: FOOTER PAGE LINKS REMOVED*/}
                {/*<List className="footer-list">*/}
                {/*  <Link to="/">Home</Link>*/}
                {/*  <Link to="/machine">Spidfier Machine</Link>*/}
                {/*  <Link to="/contact">Contact</Link>*/}
                {/*</List>*/}

                <div id="footer-logo-wrapper">
                  <img id="footer-logo" src={logo} alt="Spidfier logo" />
                </div>
              </div>
              {/*<div className="made-by">*/}
              {/*  Made with &hearts; by <a target="_blank" href="https://sambroughton.com">sambroughton.com</a>*/}
              {/*</div>*/}
            </footer>
          </div>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
